import React from "react"
import { graphql } from "gatsby"

import { BLUE, TIER_THREE } from "../constants"

import Layout from "../components/Layout/Layout"
import FlexibleContent from "../components/FlexibleContent"
import Hero from "../components/Hero/Hero"

const ServicesPageTemplate = ({ data, location }) => {
  const {
    metaTitle,
    metaDescription,
    title,
    flexibleContent,
    tierTwoImage,
    tierTwoMobileImage,
  } = data.craftServiceCategoriesDefaultEntry
  return (
    <Layout
      title={metaTitle || title}
      description={metaDescription}
      theme={BLUE}
      location={location}
    >
      <div className="js-transition-link-target" data-theme={BLUE}>
        <Hero
          heroStyle={TIER_THREE}
          heroHeading={title}
          tierTwoImage={tierTwoImage}
          tierTwoMobileImage={tierTwoMobileImage}
          theme={BLUE}
        />
        <FlexibleContent flexibleContent={flexibleContent} />
      </div>
    </Layout>
  )
}

export default ServicesPageTemplate

export const query = graphql`
  query ServicesPageQuery($slug: String) {
    craftServiceCategoriesDefaultEntry(slug: { eq: $slug }) {
      title
      metaTitle
      metaDescription
      tierTwoImage: heroImage {
        ... on Craft_images_Asset {
          id
          title
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 80, aspectRatio: 3.4)
            }
          }
        }
      }
      tierTwoMobileImage: heroImageSecondary {
        ... on Craft_images_Asset {
          id
          title
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 80, aspectRatio: 1)
            }
          }
        }
      }
      flexibleContent {
        ...flexibleContentFragment
      }
    }
  }
`
